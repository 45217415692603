module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-source-prismic/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"mata","accessToken":"MC5Za3hZc1JJQUFKUWNmTnNt.77-977-977-9fu-_ve-_vR_vv73vv73vv73vv73vv70T77-9R3fvv73vv70T77-977-977-9fO-_vWIC77-977-977-977-977-977-9","schemas":{"home":{"Global":{"uid":{"type":"UID","config":{"label":"Name page","useAsTitle":true}},"global_title":{"type":"StructuredText","config":{"multi":"paragraph","label":"Title (META)"}},"global_description":{"type":"StructuredText","config":{"multi":"paragraph","label":"Description (META)"}},"global_img":{"type":"Image","config":{"constraint":{},"thumbnails":[],"label":"Image (META)"}}},"Hero":{"hero_titleb":{"type":"StructuredText","config":{"multi":"heading2","label":"Before Title"}},"hero_titleba":{"type":"StructuredText","config":{"multi":"heading1","label":"Title Before Arrow"}},"hero_titleaa":{"type":"StructuredText","config":{"multi":"heading1","label":"Title After Arrow"}},"hero_text":{"type":"StructuredText","config":{"multi":"paragraph","label":"Text"}},"hero_btntxt":{"type":"StructuredText","config":{"multi":"paragraph","label":"Button - Text"}},"hero_btnlnk":{"type":"Link","config":{"label":"Button - Link","select":null}}}}},"lang":"*","prismicToolbar":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-hubspot/gatsby-browser.js'),
      options: {"plugins":[],"trackingCode":"4309630","respectDNT":false,"productionOnly":false},
    }]
